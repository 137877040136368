import { PictureAsPdf } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { TableBlocks } from "helper/Constants";
import Logger from "helper/Logger";
import { UserContext } from "helper/UserContext";
import UserPermission from "helper/UserPermission";
import useFeatureFlags from "helper/useFeatureFlags";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ApiContext } from "../../helper/ApiContext";
import LazyLoad from '../LazyLoadComponent';
import BlockCellComponent from "./BlockCellComponent";

const DashboardComponent = React.memo(({ pipeId, usernameFromPath }) => {

    const { api } = useContext(ApiContext);
    const [pipe, setPipe] = useState({});
    const [reloadCounter, reload] = useState(0)
    const [branchMap, setBranchMap] = useState({});
    const branchMapRef = useRef({});
    const { user } = useContext(UserContext)
    const pdfDivRef = useRef(null);
    const isAdmin = user?.permissions.some((p) => p.name === UserPermission.ADMIN)
    const { enableLazyLoading } = useFeatureFlags()
    const [blocksOrder, setBlocksOrder] = useState([])
    
    const [expandedBlocks, setExpandedBlocks] = useState([]);
    
    const toggleBlockExpansion = (blockId) => {
        setExpandedBlocks((prevExpandedBlocks) => {
            if (prevExpandedBlocks.includes(blockId)) {
                return prevExpandedBlocks.filter((id) => id !== blockId);
            } else {
                return [...prevExpandedBlocks, blockId];
            }
        })
    }
    
    const toggleAllBlocksExpansion = () => {
        setExpandedBlocks((prevExpandedBlocks) =>
            prevExpandedBlocks.length === blocks().length ? prevExpandedBlocks : allBlockFlowIDs()
        )
    }

    const onSelectionChanged = () => {
        reload(reloadCounter + 1)
    }

    useEffect(() => {
        api.getUserPipe(pipeId, usernameFromPath)
            .then((response) => {
                const pipe = response.data
                setPipe(pipe)
                if (Object.keys(branchMapRef.current).length === 0) {
                    api.getBranchMap(pipeId, usernameFromPath)
                        .then(response => {
                            const branchData = response.data;
                            setBranchMap(branchData);
                            branchMapRef.current = branchData;
                            setBlocksOrder(calculateBlockOrder(pipe.blocks, branchMapRef.current));
                        })
                        .catch(error => {
                            Logger.error("Could not fetch branch map: " + JSON.stringify(error));
                        });
                } else {
                    setBlocksOrder(calculateBlockOrder(pipe.blocks, branchMapRef.current));
                }
            }).catch((error) => {
                Logger.error("Could not get user pipe: " + JSON.stringify(error))
            })
    }, [pipeId, reloadCounter])

    const calculateBlockOrder = (blocks, branchMap) => {
        const blocksInOrderedBranches = blocks.map((block) => ({
            blockId: block.flow_id,
            branch: branchMap[block.flow_id] || block.order,
        })).sort((a, b) => a.branch - b.branch);

        let globalOrderCounter = 1;
        const orderedBlocks = blocksInOrderedBranches.map(({ blockId }) => {
            return {
                id: blockId,
                order: globalOrderCounter++,
            };
        });
        return orderedBlocks.map(({ id }) => id)
    }
    

    const blocks = useMemo(() => {
        if (pipe.blocks === undefined) 
            return []
        let fileImportBlock = pipe.blocks?.find((block) => block.blueprint.type === "import")
        if (fileImportBlock === undefined) {
            Logger.error("Could not find file import block")
            return []
        }
        let sortedBlocks = []
        let visitedBlocks = []
        let queue = [fileImportBlock]
        while (queue.length > 0) {
            let block = queue.shift()
            if (visitedBlocks.includes(block.flow_id)) 
                continue
            visitedBlocks.push(block.flow_id)
            sortedBlocks.push(block)
            let connections = pipe.connections.filter((connection) => connection.source_flow_id === block.flow_id)
            connections.forEach((connection) => {
                let targetBlock = pipe.blocks.find((block) => block.flow_id === connection.target_flow_id)
                if (targetBlock !== undefined) 
                    queue.push(targetBlock)
            })
        }
        let filteredBlocks = sortedBlocks.filter((block) => block.dashboard_configuration.view_in_dashboard)
        return filteredBlocks
    }, [pipe.blocks])

    const renderDashedLine = () => (
        <div style={{
            borderTop: '2px solid gray',
            margin: '20px 0',
            width: '100%'
        }} />
    );

    const renderPDF = () => {
        setTimeout(() => {
            const doc = new jsPDF({
                format: 'a4',
                unit: 'px',
            });
            html2canvas(pdfDivRef.current, { scale: 1.5 }).then((data) => {
                const img = data.toDataURL("image/png")
                const imgProperties = doc.getImageProperties(img)
                var docWidth = doc.internal.pageSize.getWidth()
                var docHeight = doc.internal.pageSize.getHeight()
                if (imgProperties.height / imgProperties.width > docHeight / docWidth) {
                    docWidth = (imgProperties.width * docHeight) / imgProperties.height
                } else {
                    docHeight = (imgProperties.height * docWidth) / imgProperties.width
                }
                doc.addImage(img, "PNG", 0, 0, docWidth, docHeight)
                doc.save(`${pipe.name}.pdf`)
            })
        }, 3000)
    }

    const blockCellComponent = (block, index) => {
        return enableLazyLoading ? (
            <LazyLoad
                component={BlockCellComponent}
                block={block}
                pipeId={pipeId}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                onSelectionChanged={onSelectionChanged}
                isAdmin={isAdmin}
            />
        ) : (
            <BlockCellComponent
                block={block}
                pipeId={pipeId}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                onSelectionChanged={onSelectionChanged}
                isAdmin={isAdmin}
                expanded={expandedBlocks.includes(block.flow_id)}
                toggleExpansion={() => toggleBlockExpansion(block.flow_id)}
            />
        );
    }

    const renderBlocksWithBranchSeparators = () => {
        let currentBranch = null;
        const blocksByBranch = blocks.reduce((acc, block) => {
            const blockBranch = branchMapRef.current[block.flow_id] || block.order; 
            if (!acc[blockBranch]) {
                acc[blockBranch] = [];
            }
            acc[blockBranch].push(block);
            return acc;
        }, {});
    
        Object.keys(blocksByBranch).forEach((branch) => {
            const fileImportBlock = blocksByBranch[branch].find((block) => block.blueprint.type === "import");
            let sortedBlocks = [];
            
            if (fileImportBlock) {
                let visitedBlocks = [];
                let queue = [fileImportBlock];
                while (queue.length > 0) {
                    let block = queue.shift();
                    if (visitedBlocks.includes(block.flow_id)) 
                        continue;
                    visitedBlocks.push(block.flow_id);
                    sortedBlocks.push(block);
                    
                    let connections = pipe.connections.filter((connection) => connection.source_flow_id === block.flow_id);
                    connections.forEach((connection) => {
                        let targetBlock = blocksByBranch[branch].find((block) => block.flow_id === connection.target_flow_id);
                        if (targetBlock !== undefined)
                            queue.push(targetBlock);
                    });
                }
                blocksByBranch[branch] = sortedBlocks; 
            }
        });
        return Object.keys(blocksByBranch).map((branch, branchIndex) => {
            const branchBlocks = blocksByBranch[branch];
            return (
                <React.Fragment key={branch}>
                    {branchIndex !== 0 && branch > 2 && renderDashedLine()} 
                    {branchBlocks.map((block, index) => blockCellComponent(block, index))}
                </React.Fragment>
            );
        });
    };
    

    return (
        <>
            <Box ref={pdfDivRef} sx={{ mt: 7, flexDirection: 'column' }}>
                {renderBlocksWithBranchSeparators()}
            </Box>
            {document.getElementById('toolbar-export-button-portal') && createPortal(
                <Tooltip title="Export PDF Report">
                    <IconButton color="inherit" onClick={renderPDF}>
                        <PictureAsPdf />
                    </IconButton>
                </Tooltip>,
                document.getElementById('toolbar-export-button-portal')
            )}
        </>
    )
})

export default DashboardComponent