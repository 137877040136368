import { Stack, Typography, Box } from "@mui/material";
import DataLoadingTableComponent from "components/table/DataLoadingTableComponent";
import { ApiContext } from "helper/ApiContext";
import { useContext, useState } from "react";
import { AnyContext } from "../../helper/AnyContext";
import VegaUtils from "../../helper/VegaUtils";
import PreviewLoadingPlotComponent from "./PreviewLoadingPlotComponent";
import { SpecLoadingPlotComponent } from "./SpecLoadingPlotComponent";

const BrushSelectionListPlotComponent = ({ pipeId, nodeId, reloadCounter, onSelectionChanged, usernameFromPath, loadStaticPlot = false, showTable = true }) => {
    const vegaUtils = VegaUtils();
    const { api } = useContext(ApiContext);
    const [filter, setFilter] = useState(undefined);
    const [currentSignals, setCurrentSignals] = useState([]);
    const [reloadTableCounter, reloadTable] = useState(0)
    const [plotDescriptions, setPlotDescriptions] = useState([]);
    const [canvasWidth, setCanvasWidth] = useState(0);
    var brushTimer = undefined;
    var skipNextBrushUpdateCall = false;
    
    const handleImageWidthCalculated = (width) => {
        setCanvasWidth(width);
    };

    const debounceBrushEvent = (func) => {
        if (brushTimer) {
            return
        }
        brushTimer = setTimeout(function () {
            func()
            brushTimer = undefined;
        }, 1000)
    }

    const patchVegaSpec = (spec) => {
        // manually push a mouseup signal that we can listen to
        // NOTE: we could also manually listen on the actual mouseup events of the canvas, but Vega already does that for us with this signal
        if (spec.signals) {
            spec.signals.push({
                name: "mouseup",
                on: [{
                    events: "mouseup",
                    update: "event"
                }]
            })

            spec.signals.push({
                name: "click",
                on: [{
                    events: "click",
                    update: "event"
                }]
            })
        }
    }

    const onViewLoaded = (view) => {
        let signals = vegaUtils.getCurrentBrushSelectionsFromView(view)
        Object.keys(signals).forEach(signal => {
            view.addSignalListener(signal, (event, value) => {
                debounceBrushEvent(() => {
                    updateSelectedDataPoints(view)
                })
            })
        })

        view.addSignalListener("click", (event, value) => {
            // if we have an active brush selection, we don't want to update the selected data points
            if (activeUserSelections(view).length > 0) {
                return
            }
            // if we click on the empty canvas nothing should happen
            if (value.item && value.item.tooltip) {
                let singleDatapointTooltip = value.item.tooltip
                let fakeSignal = {}
                Object.keys(singleDatapointTooltip).forEach((key) => {
                    // convert to number
                    let value = parseFloat(singleDatapointTooltip[key])
                    if (!isNaN(value)) {
                        fakeSignal[key] = [value, value]
                    }
                })
                if (Object.keys(fakeSignal).length > 0) {
                    let signals = [fakeSignal]
                    updatePipeWithCurrentSelection(signals)
                }
            }
        });

        // run once to fill initially
        skipNextBrushUpdateCall = true
        updateSelectedDataPoints(view)

        // Set canvas width for description rendering
        const canvas = view.container();
        if (canvas) {
            setCanvasWidth(canvas.scrollWidth); 
        }
    }

    const activeUserSelections = (view) => {
        // NOTE: passing all the signals instead of just the changed one, because we need to filter on all selections
        let signals = vegaUtils.getCurrentBrushSelectionsFromView(view)
        let nonNullSignals = Object.entries(signals)
            .filter(([name, value]) =>
                value && // no undefined signals
                typeof value === "object" && // no non-dict signals
                Object.keys(value).length > 0) // no empty signals
            .map(([name, value]) => value)
        return nonNullSignals
    }

    const updateSelectedDataPoints = (view) => {
        if (skipNextBrushUpdateCall) {
            skipNextBrushUpdateCall = false
            return
        }

        let nonNullSignals = activeUserSelections(view)
        updatePipeWithCurrentSelection(nonNullSignals)
    }

    const updatePipeWithCurrentSelection = (nonNullSignals) => {
        if (!isNaN(pipeId) && !isNaN(nodeId) && nonNullSignals !== currentSignals) {
            api.updateNodeInPipe(pipeId, nodeId, usernameFromPath, nonNullSignals)
                .then((response) => {
                    setCurrentSignals(nonNullSignals)
                    onSelectionChanged()
                    reloadTable(prev => prev + 1)
                    return response
                })
        }
    }
    const splitDescriptions = plotDescriptions || []; 
    const descriptionWidth = canvasWidth / (splitDescriptions.length || 1);
    
    return (
        <Stack direction="column" spacing={2}>
            <AnyContext.Provider value={{ filter, setFilter }}>
                <Stack direction="column" sx={{ overflow: "scroll" }}>
                    {(loadStaticPlot) ?
                        <PreviewLoadingPlotComponent
                            pipeId={pipeId}
                            nodeId={nodeId}
                            usernameFromPath={usernameFromPath}
                            reload={reloadCounter}
                            setPlotDescriptions={setPlotDescriptions}
                            onImageWidthCalculated={handleImageWidthCalculated}
                        /> :
                        <SpecLoadingPlotComponent
                            pipeId={pipeId}
                            nodeId={nodeId}
                            reload={reloadCounter}
                            usernameFromPath={usernameFromPath}
                            onViewLoaded={onViewLoaded}
                            patchVegaSpec={patchVegaSpec}
                            setPlotDescriptions={setPlotDescriptions}
                        />
                    }
                    <Box display="flex" justifyContent="space-between">
                        {splitDescriptions.map((desc, index) => (
                            <Typography
                                key={index}
                                style={{
                                    whiteSpace: 'pre-line',
                                    minWidth: `${descriptionWidth}px`,
                                    paddingLeft: '20px'
                                }}
                            >
                                {desc}
                            </Typography>
                        ))}
                    </Box>
                </Stack>
            </AnyContext.Provider>
            {/* {showTable && <DataTableComponent data={rows} />} */}
            {showTable && <DataLoadingTableComponent
                pipeId={pipeId}
                nodeId={nodeId}
                username={usernameFromPath}
                reloadPipeCounter={reloadTableCounter}
            />}

        </Stack>
    )
}

export default BrushSelectionListPlotComponent;