import { trimNumericalPrecisionTo4Digits } from 'helper/trimNumericalPrecisionTo4Digits';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState, useMemo } from 'react';

const DataTableComponent = ({
    data,
    config,
    isShowTableBlock = false,
    reloadData = undefined,
    rowCount = undefined,
    isLoading = false
}) => {

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const columnsFromRows = (data) => {
        if (data.length > 0) {
            return Object.keys(data[0]).filter((column) => !column.includes("Unnamed"))
        } else {
            return []
        }
    }

    const columnDicts = useMemo(() => {
        if (data === undefined || data.length === 0) { return [] }
        const allColumns = columnsFromRows(data);
        let columnsToShow = allColumns;

        if (isShowTableBlock && config && config.elements) {
            const selectedColumns = config.elements.flatMap(element => element.selected_values || []);
            columnsToShow = selectedColumns.length > 0 ? selectedColumns : allColumns;
        }

        return columnsToShow.map((column) => ({
            accessorKey: column,
            header: column
        }));
    }, [data, config, isShowTableBlock]);

    useEffect(() => {
        if (reloadData === undefined) {
            return
        }

        reloadData(
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting
        )
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    const columnOrder = useMemo(() => {
        if (isShowTableBlock && config && config.elements) {
            const selectedValues = config.elements.flatMap(element => element.selected_values || []);
            const options = config.elements.flatMap(element => element.options || []);
            
            // Ensure robustness by checking if selectedValues or options are empty
            return selectedValues.length > 0 ? selectedValues : (options.length > 0 ? options : columnsFromRows(data));
        }
        return columnsFromRows(data);
    }, [data, config, isShowTableBlock]);

    return (
        <MaterialReactTable
            columns={columnDicts}
            data={trimNumericalPrecisionTo4Digits(data)}
            columnOrder={columnOrder}
            enableStickyHeader
            enableTopToolbar={false}
            enableClickToCopy={false}
            enableColumnResizing
            enableFullScreenToggle={false}

            // remote pagination section
            manualFiltering
            manualPagination
            manualSorting
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={rowCount}

            // row selection section
            selectAllMode={"all"}
            displayColumnDefOptions={{
                'mrt-row-select': { size: 20 },
                'mrt-row-actions': { size: 20 }
            }}

            // state management
            initialState={{
                density: 'compact',
                columnOrder: columnOrder,
                sorting: columnOrder.length > 0 ? [{ id: columnOrder[0], desc: false }] : []
            }}
            muiTablePaginationProps={{
                labelRowsPerPage: ""
            }}
            state={{
                columnOrder: columnOrder,
                columnFilters: columnFilters,
                globalFilter: globalFilter,
                pagination: pagination,
                showProgressBars: isLoading,
                sorting: sorting,
            }}
        />
    )
}

export default DataTableComponent;