import { Link, LinkOff } from '@mui/icons-material';
import { Button, Checkbox, Stack, Tooltip, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DefaultPaper from 'components/DefaultPaper';
import MultiSelectConfigList from 'components/MultiSelectConfigList';
import SingleSelectConfigList from 'components/SingleSelectConfigList';
import { useEffect, useState } from 'react';

export const ConfigurePlotElementHeader = ({ config, onChangeIsSynced }) => {
    return (
        <Stack direction="row">
            <Typography variant="h6">{config.name}</Typography>
            {!(isNaN(config.is_synced) || config.is_synced === null) && (
                <Tooltip title={config.is_synced ? "Unsync axes" : "Sync axes"}>
                    <Checkbox
                        sx={{ mt: -0.5 }}
                        checked={config.is_synced}
                        icon={<LinkOff />}
                        checkedIcon={<Link />}
                        onChange={(event) => {
                            let newIsSynced = event.target.checked
                            onChangeIsSynced(newIsSynced)
                        }}
                    />
                </Tooltip>
            )}
        </Stack>
    )
}

const ConfigurePlotSidebarComponent = ({
    onSaveConfig,
    isForeignPipe,
    initialConfig = {},
    isShowTableBlock = false
}) => {

    const [state, setState] = useState({ elements: [] })

    useEffect(() => {
        setState(initialConfig)
    }, [initialConfig])

    const onSelectSingleSelectOption = (option, index) => {
        let newState = { ...state }
        newState.elements[index].selected_values = [option]
        setState(newState)
    }

    const onSelectMultiSelectOption = (option, index) => {
        let newState = { ...state }
        let values = newState.elements[index].selected_values
        if (values.includes(option)) {
            values = values.filter((value) => value !== option)
        } else {
            values.push(option)
        }
        newState.elements[index].selected_values = values
        setState(newState)
    }

    const onReorderOptions = (index, newOptions, selectedValues) => {
        let newState = { ...state };
        // Update the options with the new order
        newState.elements[index].options = newOptions;
        // Update the selected_values based on the new order of options
        const newSelectedValues = newOptions.filter(option => selectedValues.includes(option));
        newState.elements[index].selected_values = newSelectedValues;
        setState(newState);  // Update the state with both reordered options and selected_values
    };
    

    const onChangeIsSynced = (index, value) => {
        let newState = { ...state }
        newState.elements[index].is_synced = value
        setState(newState)
    }

    return (
        <DefaultPaper
            additionalSx={{ height: '80vh', overflow: "auto", pl: 0, pt: 0 }}
        >
            <List>
                {state.elements.map((config, index) => (
                    <ListItem key={index}>
                        <Stack direction="column" sx={{ width: '100%' }}>
                            {config.type === "single_select" &&
                                <SingleSelectConfigList
                                    config={config}
                                    onSelectOption={(option) => onSelectSingleSelectOption(option, index)}
                                    isForeignPipe={isForeignPipe}
                                    onChangeIsSynced={(value) => (onChangeIsSynced(index, value))}
                                />}
                            {config.type === "multi_select" &&
                                <MultiSelectConfigList
                                    config={config}
                                    onSelectOption={(option) => onSelectMultiSelectOption(option, index)}
                                    isForeignPipe={isForeignPipe}
                                    onChangeIsSynced={(value) => (onChangeIsSynced(index, value))}
                                    onOptionsReorder={(newOptions, selectedValues) => onReorderOptions(index, newOptions, selectedValues)} // Pass the callback
                                    isShowTableBlock={isShowTableBlock}
                                />}
                        </Stack>
                    </ListItem>
                ))}
                <Button
                    variant="contained"
                    sx={{ ml: 2, mb: 2 }}
                    onClick={() => onSaveConfig(state)}
                    disabled={isForeignPipe()}
                >
                    Save & Reload
                </Button>
            </List>
        </DefaultPaper>
    )
}

export default ConfigurePlotSidebarComponent