import ClonePipeDialogComponent from "components/dialogs/ClonePipeDialogComponent";
import { encodePipeId, useIsForeignPipe, useURLParts } from "helper/UrlUtils";
import { UserContext } from "helper/UserContext";
import { toPng } from "html-to-image";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Background, Controls, ReactFlow, useEdgesState, useNodesState } from "@xyflow/react";
import '@xyflow/react/dist/style.css';
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import DefaultPaper from "../DefaultPaper";
import CustomFlowEdge from "./edges/CustomFlowEdge";
import CustomFlowNode from "./nodes/CustomFlowNode";
import usePrevious from "../../helper/usePrevious";
import ChangeBranchOrderDialog from "./ChangeBranchOrderDialog";

const PipelineGraphComponent = ({ height, width, onDrop, setReactFlowInstance, isReadOnly }) => {
    const branchMapRef = useRef({});
    const edgesRef = useRef({});
    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext);
    const { pipeId, username, isTemplate } = useURLParts()
    const navigate = useNavigate();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const isForeignPipe = useIsForeignPipe(username);
    const [dialogOpen, setDialogOpen] = useState(false)
    const pipeIdRef = useRef()
    pipeIdRef.current = pipeId
    const [branchMap, setBranchMap] = useState({});
    const [selectedBranchNumber, setSelectedBranchNumber] = useState(null);
    const [changeOrderDialogOpen, setChangeOrderDialogOpen] = useState(false);
    const [branchOrder, setBranchOrder] = useState({});
    const previousNodeCount = usePrevious(nodes.length);
    const previousEdgeCount = usePrevious(edges.length);
    const [skipFlag, setSkipFlag] = useState(false);

    const handleBranchOrderChange = (branchNumber, newOrder) => {
        setBranchOrder(prevState => {
            const updatedOrder = { ...prevState };
            const affectedBranch = Object.keys(updatedOrder).find(
                key => updatedOrder[key] === parseInt(newOrder)
            );

            if (affectedBranch) {
                updatedOrder[affectedBranch] = updatedOrder[branchNumber];
            }
            updatedOrder[branchNumber] = parseInt(newOrder);

            return updatedOrder;
        });
    };

    const handleSubmitOrderChanges = () => {
        let updatedBranchMap = { ...branchMap };
        Object.keys(branchOrder).forEach((branchNumber) => {
            const newOrder = branchOrder[branchNumber];
            Object.keys(branchMap).forEach(nodeId => {
                if (branchMap[nodeId] === parseInt(branchNumber)) {
                    updatedBranchMap[nodeId] = parseInt(newOrder);
                }
            });

        });

        branchMapRef.current = updatedBranchMap;
        setBranchMap(updatedBranchMap);

        const blocks = Object.keys(updatedBranchMap).map(nodeId => ({
            id: nodeId,
            order: updatedBranchMap[nodeId]
        }));
        api.updateBlockOrder(pipeId, blocks, username)
            .then(response => {
            })
            .catch(error => {
                console.error("Error updating block order:", error);
            });

        handleCloseChangeOrderDialog();
    };

    const calculateBranchNumbers = (nodes, edges, previousBranchMap = {}) => {
        let branchMap = { ...previousBranchMap };
        let visitedNodes = new Set();
        let currentBranch = 0;
        edgesRef.current = edges
        const filteredValues = Object.values(branchMapRef.current).filter(val => val !== 0);
        const allValuesSame = filteredValues.every(val => val === filteredValues[0]);
        const getOutgoingEdges = (nodeId) => {
            return edges.filter(edge => edge.source === nodeId);
        };

        const getIncomingEdges = (nodeId) => {
            return edges.filter(edge => edge.target === nodeId);
        };
        const fileImportNode = nodes.find(node => node?.data?.blueprint?.type === "import");
        const fileImportEdges = fileImportNode ? edges.filter(edge => edge.source === fileImportNode.id) : [];

        const traverseInSeries = (startNodeId, branchNumber) => {
            let queue = [startNodeId];

            while (queue.length > 0) {
                const currentNode = queue.shift();
                if (visitedNodes.has(currentNode)) continue;
                visitedNodes.add(currentNode);
                if ((edges.length > 2 || skipFlag) && !allValuesSame && (fileImportEdges.length > 1)) {
                    if (skipFlag) {
                        setSkipFlag(false);
                    }
                    if (branchMapRef.current && branchMapRef.current[currentNode]) {
                        branchMap[currentNode] = branchMapRef.current[currentNode];
                        if (branchMap[currentNode] !== branchNumber) {
                            setBranchOrder(prevState => ({
                                ...prevState,
                                [branchNumber]: branchMapRef.current[currentNode]
                            }));
                        }
                    } else {
                        const customBranchNumber = parseInt(branchOrder[branchNumber]) || branchNumber;
                        branchMap[currentNode] = customBranchNumber;
                    }
                } else {
                    const customBranchNumber = parseInt(branchOrder[branchNumber]) || branchNumber;
                    branchMap[currentNode] = customBranchNumber;
                }

                const outgoingEdges = getOutgoingEdges(currentNode);

                if (outgoingEdges.length > 1) {
                    outgoingEdges.forEach((edge) => {
                        currentBranch++;
                        traverseInSeries(edge.target, currentBranch);
                    });
                } else if (outgoingEdges.length === 1) {
                    queue.push(outgoingEdges[0].target);
                }
            }
        };

        if (fileImportNode) {
            currentBranch++;
            traverseInSeries(fileImportNode.id, currentBranch);
        }

        nodes.forEach(node => {
            if (!visitedNodes.has(node.id)) {
                const outgoingEdges = getOutgoingEdges(node.id);
                const incomingEdges = getIncomingEdges(node.id);

                if (outgoingEdges.length === 0 && incomingEdges.length === 0) {
                    branchMap[node.id] = 0;
                } else {
                    currentBranch++;
                    traverseInSeries(node.id, currentBranch);
                }
            }
        });
        Object.keys(branchOrder).forEach(branchNumber => {
            if (!Object.values(branchMap).includes(parseInt(branchNumber))) {
                delete branchOrder[branchNumber];
            }
        });

        branchMapRef.current = branchMap;
        setBranchMap(branchMap);
    };


    const handleOpenChangeOrderDialog = (nodeId) => {
        const branchNumber = branchMapRef.current[nodeId];

        if (branchNumber) {
            const currentBranchOrder = Object.keys(branchMapRef.current).reduce((order, nodeId) => {
                const branch = branchMapRef.current[nodeId];
                order[branch] = branchOrder[branch] || branch;
                return order;
            }, {});

            setBranchOrder(currentBranchOrder);
            setSelectedBranchNumber(branchNumber);
            setChangeOrderDialogOpen(true);
        } else {
            console.error("BranchMap is not populated or nodeId is invalid");
        }
    };

    const handleCloseChangeOrderDialog = () => {
        setChangeOrderDialogOpen(false);
        setSelectedBranchNumber(null);
    };

    useEffect(() => {
        if (!isNaN(pipeId)) {
            loadPipeFromApi()

            if (Object.keys(branchMapRef.current).length === 0) {
                api.getBranchMap(pipeId, username)
                    .then(response => {
                        setBranchMap(response.data);
                        branchMapRef.current = response.data;
                        if (Object.keys(response.data).length === 3) {
                            setSkipFlag(true);
                        }

                    })
                    .catch(error => {
                        console.error("Failed to fetch branch map:", error);
                    });
            }
        }
    }, [pipeId])

    const handleReadonlyError = () => {
        setDialogOpen(true)
    }

    const loadPipeFromApi = () => {
        if (isTemplate) {
            api.getTemplate(pipeId)
                .then((response) => {
                    let { blocks, connections } = response.data
                    loadRemoteNodes(blocks)
                    loadRemoteEdges(connections)
                    handleReadonlyError()
                }).catch((error) => {
                    Logger.error("Could not get user pipe: " + JSON.stringify(error))
                })
        } else {
            api.getUserPipe(pipeId, username)
                .then((response) => {
                    let { blocks, connections } = response.data
                    loadRemoteNodes(blocks)
                    loadRemoteEdges(connections)
                }).catch((error) => {
                    Logger.error("Could not get user pipe: " + JSON.stringify(error))
                })
        }
    }

    const loadRemoteNodes = (nodes) => {
        let patchedNodes = nodes.map((node) => {
            const branchNumber = branchMapRef.current[node.flow_id];
            return {
                id: `${node.flow_id}`,
                data: {
                    ...node,
                    branchNumber: branchNumber || 0
                },
                position: node.position,
                type: "custom"
            };
        });
        setNodes(patchedNodes);  // Ensure this updates the node state with the branch number
    };

    const createEdgeDict = (base) => {
        return {
            id: `e${base.source}-${base.sourceHandle}-${base.target}-${base.targetHandle}`,
            type: 'custom',
            animated: 'true',
            style: { stroke: 'black' },
            ...base
        }
    }

    const loadRemoteEdges = (edges) => {
        let patchedEdges = edges.map((edge) => createEdgeDict({
            source: edge.source_flow_id.toString(),
            sourceHandle: edge.source_handle_id?.toString(),
            target: edge.target_flow_id.toString(),
            targetHandle: edge.target_handle_id?.toString()
        })
        )
        edgesRef.current = patchedEdges
        setEdges(patchedEdges)
    }

    const isValidConnection = (connection) => {
        let edges = edgesRef.current
        const sourceBranchNumber = branchMapRef.current[connection.source];
        let otherEdgesOfTargetNode = edges.filter((edge) => (edge.target === connection.target && edge.targetHandle === connection.targetHandle))

        if (sourceBranchNumber === 1 && otherEdgesOfTargetNode.length === 0) {
            return true;
        }
        if (sourceBranchNumber !== 1 && otherEdgesOfTargetNode.length === 0) {
            let outgoingEdgesCount = edges.filter(edge => edge.source === connection.source).length;
            return outgoingEdgesCount === 0
        }
    }

    const onConnect = useCallback((params) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }

        const sourceBranchNumber = branchMapRef.current[params.source];
        const targetNodeId = params.target;
        const updateBranchForConnectedNodes = (nodeId, newBranchNumber) => {
            branchMapRef.current[nodeId] = newBranchNumber;
            const outgoingEdges = edgesRef.current.filter(edge => edge.source === nodeId);
            outgoingEdges.forEach(edge => {
                updateBranchForConnectedNodes(edge.target, newBranchNumber);
            });
        };
        if (sourceBranchNumber !== undefined && sourceBranchNumber !== 1) {
            updateBranchForConnectedNodes(targetNodeId, sourceBranchNumber);
        }

        let edgeDict = createEdgeDict(params)
        api.addEdgeToPipe(pipeIdRef.current, edgeDict, username).then((response) => {
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
    }, [user])

    const onDragOver = useCallback((event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }, [])

    const uploadScreenshot = () => {
        toPng(document.querySelector('.react-flow'), { pixelRatio: 0.5 })
            .then((data) => {
                api.uploadScreenshot(pipeIdRef.current, data, username)
                    .then((response) => {
                    }).catch((error) => {
                        Logger.error("Error uploading screenshot: " + JSON.stringify(error))
                    })
            });
    }

    const newUniqueNodeId = () => {
        let newNodeId = nodes.length + 1
        while (nodes.find((node) => node.id === newNodeId.toString())) {
            newNodeId += 1
        }
        return newNodeId.toString()
    }

    const addNode = (node) => {
        if (isForeignPipe() || !node) {
            handleReadonlyError()
            return
        }
        node.id = newUniqueNodeId()
        api.addNodeToPipe(pipeIdRef.current, node, username).then((response) => {
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
        // uploadScreenshot()
    }

    const onNodeClick = (event, node) => {
        if (document.getElementById("basic-menu") !== null) {
            // for some reason clicking away from the node context menu triggers the `onNodeClick` event
            // so we have to check if the menu is still there
            return
        }
        if (isTemplate) {
            // don't allow entering blocks in templates
            return
        }
        if (isReadOnly && !node.data.dashboard_configuration.view_in_dashboard) {
            // don't allow non-admins to go into blocks that are hidden from the dashboard
            return
        }
        // TODO: <finn> find a way to abort running requests on the backend that we will never see
        const pipeUrl = encodePipeId(username, pipeId)
        navigate(`/pipe/${pipeUrl}/block/${node.id}`)
    }

    const onNodeDragStop = (event, node) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        if (node) {
            api.moveNodeInPipe(pipeIdRef.current, node.id, node.position, username).then((response) => {
            }).catch((error) => {
                Logger.error("Error updating user pipe: " + JSON.stringify(error))
            })
            // uploadScreenshot()
        }
    }

    const onDropdownValueChanged = (nodeId, selectedOptions) => {
        api.updateNodeInPipe(pipeIdRef.current, nodeId, username, selectedOptions).then((response) => {
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
    }

    const onDeleteNode = (nodeId) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        setNodes((nodes) => nodes.filter((node) => node.id !== nodeId))
        setEdges((edges) => edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId))
        api.deleteNodeFromPipe(pipeIdRef.current, nodeId, username).then((response) => {
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
        // uploadScreenshot()
    }

    const onChangeDashboardFlag = (nodeId, checked) => {
        api.updateNodeInDashboard(pipeIdRef.current, nodeId, username, checked).then((response) => {
        }).catch((error) => {
            Logger.error("Error updating the block: " + JSON.stringify(error))
        })
    }

    const onChangeSkipFlag = (nodeId, checked) => {
        api.updateNodeSkipFlag(pipeIdRef.current, nodeId, username, checked).then((response) => {
        }).catch((error) => {
            Logger.error("Error updating the block: " + JSON.stringify(error))
        })
    }

    const onDeleteEdge = (edgeId) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        setEdges((edges) => edges.filter((edge) => edge.id !== edgeId))
        api.deleteEdgeFromPipe(pipeIdRef.current, edgeId, username).then((response) => {
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
        // uploadScreenshot()
    }

    const nodeTypes = useMemo(() => ({
        custom: (reactFlowProps) => <CustomFlowNode
            pipeId={pipeId}
            usernameFromPath={username}
            isReadOnly={isReadOnly}
            isValidConnection={isValidConnection}
            onValueChanged={(selectedOption) => {
                onDropdownValueChanged(reactFlowProps.id, selectedOption)
            }}
            onDeleteButtonClick={(nodeId) => {
                onDeleteNode(nodeId)
            }}
            onCheckboxClick={(id, name, checked) => {
                if (name === 'Skip') {
                    onChangeSkipFlag(id, checked)
                } else {
                    onChangeDashboardFlag(id, checked)
                }
            }}
            onOpenChangeOrder={handleOpenChangeOrderDialog}
            {...reactFlowProps}
        />,
    }), [user])

    const edgeTypes = useMemo(() => ({
        custom: (reactFlowProps) => <CustomFlowEdge
            onCloseButtonClick={(edgeId) => {
                onDeleteEdge(edgeId)
            }}
            {...reactFlowProps}
        />
    }), [user])

    useEffect(() => {
        if ((previousNodeCount !== nodes.length || previousEdgeCount !== edges.length) && nodes.length > 0 && edges.length > 0) {
            calculateBranchNumbers(nodes, edges);
            const blocks = Object.keys(branchMapRef.current).map(nodeId => ({
                id: nodeId,
                order: branchMapRef.current[nodeId]
            }));
            api.updateBlockOrder(pipeId, blocks, username)
                .then(response => {
                })
                .catch(error => {
                    console.error("Error updating block order:", error);
                });
        }
    }, [nodes.length, edges.length]);

    return (
        <DefaultPaper additionalSx={{
            height: height,
            width: width,
            padding: -10,
            overflow: "hidden",
        }}>
            <ClonePipeDialogComponent
                open={dialogOpen}
                setOpen={setDialogOpen}
                usernameFromPath={username}
                pipeId={pipeId}
                isTemplate={isTemplate}
            />
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodesDraggable={!isReadOnly}
                nodesConnectable={!isReadOnly}
                elementsSelectable={!isReadOnly}
                onInit={setReactFlowInstance}
                onDrop={(event) => {
                    addNode(onDrop(event))
                }}
                onDragOver={onDragOver}
                onNodeClick={onNodeClick}
                onNodeDragStop={onNodeDragStop}
                fitView
            >
                <Background
                    color="#000000"
                    style={{ backgroundColor: "#F5f5f5" }}
                />
                <Controls />
            </ReactFlow>
            <ChangeBranchOrderDialog
                open={changeOrderDialogOpen}
                onClose={handleCloseChangeOrderDialog}
                branchOrder={branchOrder}
                branchMap={branchMapRef.current}
                selectedBranchNumber={selectedBranchNumber}
                handleBranchOrderChange={handleBranchOrderChange}
                handleSubmitOrderChanges={handleSubmitOrderChanges}
            />
        </DefaultPaper>
    )
}

export default PipelineGraphComponent