import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import DefaultPaper from "components/DefaultPaper";
import DeleteDialogComponent from "components/dialogs/DeleteDialogComponent";
import SingleSelectConfigList from "components/SingleSelectConfigList";
import StaticDataTableComponent from "components/table/StaticDataTableComponent";
import { ApiContext } from "helper/ApiContext";
import Logger from "helper/Logger";
import { useContext, useEffect, useState } from "react";
import SignupPageComponent from "./SignupPageComponent";

const UserRole = {
    Admin: "Admin",
    User: "User"
}

const ChangeRoleComponent = ({ selectedOption, onSelectOption }) => {
    return (
        <DefaultPaper additionalSx={{ mt: 0 }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h4">Change Role</Typography>
                <SingleSelectConfigList
                    config={{
                        selected_values: [selectedOption],
                        options: [UserRole.Admin, UserRole.User]
                    }}
                    onSelectOption={onSelectOption}
                    displayNone={false}
                />
            </Stack>
        </DefaultPaper>
    )
}

const ChangeOrganizationComponent = ({ selectedOption, onSelectOption }) => {
    const { api } = useContext(ApiContext)
    const [organizations, setOrganizations] = useState([])

    useEffect(() => {
        api.getOrganizations()
            .then((response) => {
                setOrganizations(response.data.map((org) => org.name))
            })
            .catch((error) => {
                Logger.error(error)
            })
    }, [])

    return (
        <DefaultPaper additionalSx={{ mt: 0 }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h4">Change Organization</Typography>
                <SingleSelectConfigList
                    config={{
                        selected_values: [selectedOption],
                        options: organizations
                    }}
                    onSelectOption={onSelectOption}
                    displayNone={false}
                />
            </Stack>
        </DefaultPaper>
    )
}

const ResetPasswordDialog = ({ username, open, onClose, onSuccess }) => {
    let { api } = useContext(ApiContext)
    let [newPassword, setNewPassword] = useState("")

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Do you really want to reset the password for user <b>{username}</b>?</DialogTitle>
            <DialogContent>
                <DialogContentText>Please enter a new password for the user.</DialogContentText>
                <TextField
                    label="password"
                    type="password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => {
                        setNewPassword(e.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => {
                    onClose()
                    api.resetPassword(username, newPassword)
                        .then(onSuccess)
                }} variant="contained">Reset Password</Button>
            </DialogActions>
        </Dialog>
    )
}

export const ChangeCostCentersComponent = ({ target, originalCostCentersValue, onClose }) => {
    let [costCenters, setCostCenters] = useState(originalCostCentersValue)

    const isValidCostCenters = (costCenters) => {
        if (costCenters === '' || !costCenters) return true;
        const costCenterPattern = /^(all|[A-Z]{2})-(all|\d{5})$/;
        const costCenterList = costCenters.split(',');
        return costCenterList.every(costCenter => costCenterPattern.test(costCenter.trim()));
    };

    return (
        <DefaultPaper additionalSx={{ mt: 0 }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h5">Change the Cost Centers and Crops for <b>{target}</b></Typography>
                <Typography variant="body">Please enter a comma-separated list of values in the format <b>CROP-COST CENTER</b>. You are allowed to use 'all' as a placeholder for both values.</Typography>
                <TextField
                    label="Cost Centers"
                    variant="outlined"
                    value={costCenters}
                    onChange={(e) => {
                        setCostCenters(e.target.value)
                    }}
                    error={!isValidCostCenters(costCenters)}
                    helperText={!isValidCostCenters(costCenters) ? "Invalid format. Use format CROP-COST CENTER, separated by commas." : ""}
                />
                <Button 
                    onClick={() => {
                        if (isValidCostCenters(costCenters)) {
                            onClose(costCenters)
                        }
                    }} 
                    variant="contained"
                    disabled={!isValidCostCenters(costCenters)}
                >
                    Save Changes
                </Button>

            </Stack>
        </DefaultPaper>
    )
}

const UserManagementPageComponent = () => {

    let { api } = useContext(ApiContext)
    let [users, setUsers] = useState([])
    let [reloadCounter, reloadUsers] = useState(0)
    let [createAccountDialogOpen, setCreateAccountDialogOpen] = useState(false)
    let [changeRoleDialogUser, setChangeRoleDialogUser] = useState(undefined)
    let [changeOrganizationDialogUser, setChangeOrganizationDialogUser] = useState(undefined)
    let [resetPasswordDialogUser, setResetPasswordDialogUser] = useState(undefined)
    let [changeCostCentersUser, setChangeCostCentersUser] = useState(undefined)
    let [userToDelete, setUserToDelete] = useState(undefined)

    useEffect(() => {
        api.getUsers()
            .then((response) => {
                parseUsers(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [reloadCounter])

    const parseUsers = (users) => {
        let fixedUsers = users.map((user) => ({
            username: user.username,
            name: user.name,
            organization: user.organization.name,
            role: (user.permissions.some((p) => (p.name == "admin"))) ? UserRole.Admin : UserRole.User,
            cost_centers: user.cost_centers
        }))
        setUsers(fixedUsers)
    }

    const generateRowActionItems = ({ closeMenu, row }) => {
        return [
            <MenuItem
                key={0}
                onClick={() => {
                    setResetPasswordDialogUser(row.original)
                    closeMenu()
                }}
            >
                Reset Password
            </MenuItem>,
            <MenuItem
                key={1}
                onClick={() => {
                    setChangeRoleDialogUser(row.original)
                    closeMenu()
                }}
            >
                Change roles
            </MenuItem>,
            <MenuItem
                key={2}
                onClick={() => {
                    setChangeOrganizationDialogUser(row.original)
                    closeMenu()
                }}
            >
                Change organization
            </MenuItem>,
            <MenuItem
                key={3}
                onClick={() => {
                    setChangeCostCentersUser(row.original)
                    closeMenu()
                }}
            >
                Change cost centers and crops
            </MenuItem>,
            <MenuItem
                key={4}
                onClick={() => {
                    setUserToDelete(row.original)
                    closeMenu()
                }}
            >
                Delete User
            </MenuItem>
        ]
    }

    return (
        <DefaultPaper>
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                    <Typography variant="h5">User Management</Typography>
                    <Tooltip title="Please create users in the external auth tool">
                        <span>
                            <Button
                                variant="contained"
                                size="small"
                                disabled={true}
                                startIcon={<Add />}
                                onClick={() => {
                                    setCreateAccountDialogOpen(true)
                                }}
                            >
                                Create new user
                            </Button>
                        </span>
                    </Tooltip>
                </Stack>
                <StaticDataTableComponent
                    data={users}
                    generateRowActionItems={generateRowActionItems}
                />
            </Stack>
            {/* TODO: show success message for all of these actions */}
            <Dialog open={createAccountDialogOpen} onClose={() => setCreateAccountDialogOpen(false)}>
                <SignupPageComponent onClose={() => setCreateAccountDialogOpen(false)} />
            </Dialog>
            <ResetPasswordDialog
                username={resetPasswordDialogUser?.username}
                open={resetPasswordDialogUser !== undefined}
                onClose={() => setResetPasswordDialogUser(undefined)}
                onSuccess={() => { reloadUsers(reloadCounter + 1) }}
            />
            <Dialog
                open={changeRoleDialogUser !== undefined}
                onClose={() => setChangeRoleDialogUser(undefined)}
            >
                <ChangeRoleComponent
                    selectedOption={changeRoleDialogUser?.role}
                    onSelectOption={(option) => {
                        setChangeRoleDialogUser(undefined)
                        api.updateUserRole(changeRoleDialogUser?.username, option)
                            .then((response) => {
                                reloadUsers(reloadCounter + 1)
                            })
                    }}
                />
            </Dialog>
            <Dialog
                open={changeOrganizationDialogUser !== undefined}
                onClose={() => setChangeOrganizationDialogUser(undefined)}
            >
                <ChangeOrganizationComponent
                    selectedOption={changeOrganizationDialogUser?.organization}
                    onSelectOption={(option) => {
                        setChangeOrganizationDialogUser(undefined)
                        api.updateUserOrganization(changeOrganizationDialogUser?.username, option)
                            .then((response) => {
                                reloadUsers(reloadCounter + 1)
                            })
                    }}
                />
            </Dialog>
            <Dialog
                open={changeCostCentersUser !== undefined}
                onClose={() => setChangeCostCentersUser(undefined)}
            >
                <ChangeCostCentersComponent
                    target={changeCostCentersUser?.username}
                    originalCostCentersValue={changeCostCentersUser?.cost_centers}
                    onClose={(cost_centers) => {
                        setChangeCostCentersUser(undefined)
                        api.updateUserCostCenters(changeCostCentersUser?.username, cost_centers)
                            .then((response) => {
                                reloadUsers(reloadCounter + 1)
                            })
                    }}
                />
            </Dialog>
            <DeleteDialogComponent
                open={userToDelete !== undefined}
                objectName={userToDelete?.username}
                onClose={() => setUserToDelete(undefined)}
                onDeleteConfirmed={() => {
                    api.deleteUser(userToDelete.username)
                        .then((response) => {
                            reloadUsers(reloadCounter + 1)
                        })
                }}
            />
        </DefaultPaper>
    )
}

export default UserManagementPageComponent;