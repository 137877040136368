import axios from 'axios';
import { useRef, useState } from "react";
import LocalStorageService from "./LocalStorageService";
import { zipFiles } from './zipFiles';

const Api = () => {

    const state = useRef({
        client: axios.create({
            baseURL: window.REACT_APP_API_ENDPOINT,
            timeout: 60000,
            headers: {
                Accept: "application/json"
            }
        }),
        localStorageService: LocalStorageService()
    })

    const [currentError, setCurrentError] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [currentUserUndefined, setCurrentUserUndefined] = useState(false)

    const setAxiosAuthInterceptor = () => {
        state.current.client.interceptors.request.use(
            config => {
                if (config.needs_auth) {
                    const token = state.current.localStorageService.getAccessToken();
                    config.headers['Authorization'] = `Bearer ${token}`
                }
                return config;
            },
            error => {
                Promise.reject(error)
            })
    }

    const setAxiosResponseInterceptor = () => {
        state.current.client.interceptors.response.use((response) => response, (error) => {
            if (error && error.response) {
                let res = error.response
                // we handle some errors at the callsite, but fall back to the generic error handler otherwise here
                if (res.status === 400) {
                    // TODO: do we need custom error classes?
                } else if (res.status === 401) {
                    setCurrentUserUndefined(true)
                } else {
                    let defaultErrorMessage = `This feature is currently under construction. We're already working on it.`
                    if (res.data instanceof Blob) { // eg. for preview images
                        res.data.text().then((text) => {
                            let json = JSON.parse(text)
                            if (json && json.type) {
                                setCurrentError(json.type)
                            } else {
                                setCurrentError(defaultErrorMessage)
                            }
                        })
                    } else if (res.data?.type) {
                        setCurrentError(res.data?.type)
                    } else {
                        // for demo purposes we don't actually show the error, but instead a friendly message
                        setCurrentError(defaultErrorMessage)
                    }

                    setTimeout(() => {
                        setCurrentError(null)
                    }, 5000)
                }
            }
            return Promise.reject(error);
        })
    }

    const ping = () => {
        return state.current.client.get("/ping", {
            needs_auth: false
        })
    }

    const signup = (params) => {
        return state.current.client.post("/auth/signup", params, {
            needs_auth: true
        })
    }

    const login = (params) => {
        return state.current.client.post("/auth/login", params, {
            needs_auth: false
        })
            .then((response) => {
                if (response.status === 200) {
                    // TODO: check if token exists in response
                    state.current.localStorageService.setToken(response.data.token);
                }
            })
    }

    const resetPassword = (user, newPassword) => {
        let params = {
            user: user,
            new_password: newPassword
        }
        return state.current.client.post("/auth/reset-password", params, {
            needs_auth: false
        })
    }

    const getBlocks = () => {
        return state.current.client.get("/blocks", {
            needs_auth: true
        });
    }

    const getOrganizations = () => {
        return state.current.client.get("/organizations", {
            needs_auth: true
        });
    }

    const updateBlockOrder = (pipe_id, blocks, username) => {
        return state.current.client.post(`/user/${username}/pipe/${pipe_id}/block_order`, { blocks }, {
            needs_auth: true
        });
    };

    const getTemplates = () => {
        return state.current.client.get("/user/templates", {
            needs_auth: true
        });
    }

    const getTemplate = (templateId) => {
        return state.current.client.get(`/user/template/${templateId}`, {
            needs_auth: true
        });
    }

    const createTemplate = (pipeId, username) => {
        let params = {
            pipe_id: pipeId
        }
        // TODO: change route in backend to `/user/${username}/templates`
        return state.current.client.post(`/user/templates`, params, {
            needs_auth: true
        });
    }

    const updateTemplateTag = (template_id, tag) => {
        let params = {
            operation: "update_tag",
            value: tag
        }
        return state.current.client.post(`/user/template/${template_id}`, params, {
            needs_auth: true
        })
    }

    const deleteTemplate = (template_id) => {
        return state.current.client.delete(`/user/template/${template_id}`, {
            needs_auth: true
        })
    }

    const getUserProfile = () => {
        return state.current.client.get("/user/profile", {
            needs_auth: true
        })
            .then((response) => {
                if (response.status === 200) {
                    // TODO: parse into model?
                    setCurrentUser(response.data)
                }
                return response
            })
    }

    const getUserPipes = (username) => {
        let url = username ? `/user/${username}/pipes` : "/user/pipes"
        return state.current.client.get(url, {
            needs_auth: true
        })
    }

    const getUserPipe = (pipe_id, username) => {
        return state.current.client.get(`/user/${username}/pipe/${pipe_id}`, {
            needs_auth: true
        })
    }

    const getBlock = (pipe_id, block_id, username) => {
        return state.current.client.get(`/user/${username}/pipe/${pipe_id}/block/${block_id}`, {
            needs_auth: true
        })
    }

    const getBranchMap = (pipe_id, username) => {
        return state.current.client.get(`/user/${username}/pipe/${pipe_id}/branch_map`, {
            needs_auth: true
        });
    };

    const createNewPipe = (name, template_id) => {
        let params = {
            name: name
        }
        if (template_id) {
            params.template_id = template_id
        }
        return state.current.client.post("/user/pipes/new", params, {
            needs_auth: true
        })
    }

    const clonePipe = (pipe_id, owner) => {
        let params = {
            pipe_id: pipe_id,
            owner: owner
        }

        return state.current.client.post("/user/pipes/clone", params, {
            needs_auth: true
        })
    }

    const deletePipe = (username, pipe_id) => {
        return state.current.client.delete(`/user/${username}/pipe/${pipe_id}`, {
            needs_auth: true
        })
    }

    const getUserFiles = (username) => {
        return state.current.client.get(`/user/${username}/files`, {
            needs_auth: true
        })
    }

    const getFile = (filename) => {
        return state.current.client.get(`/user/file/${filename}`, {
            needs_auth: true
        })
    }

    const updateFileDTypeMapping = (filename, mapping) => {
        let params = {
            "operation": "update_dtype_mapping",
            "value": mapping
        }
        return state.current.client.post(`/user/file/${filename}`, params, {
            needs_auth: true
        })
    }

    const updateFileCostCenters = (filename, cost_centers) => {
        let params = {
            "operation": "update_cost_centers",
            "value": cost_centers
        }
        return state.current.client.post(`/user/file/${filename}`, params, {
            needs_auth: true
        })
    }

    const updateFileCrop = (filename, crop) => {
        let params = {
            "operation": "update_crop",
            "value": crop
        }
        return state.current.client.post(`/user/file/${filename}`, params, {
            needs_auth: true
        })
    }

    const getFilterConfig = (pipe_id, block_id, username) => {
        return state.current.client.get(`/user/${username}/pipe/${pipe_id}/filter_config/${block_id}`, {
            needs_auth: true
        })
    }

    const updateUserPipe = (pipe_id, params, username) => {
        return state.current.client.post(`/user/${username}/pipe/${pipe_id}`, params, {
            needs_auth: true
        })
    }

    const addNodeToPipe = (pipe_id, node, username) => {
        const dashboard_configuration = {
            view_in_dashboard: true
        }
        let params = {
            operation: "add_block",
            value: {
                position: node.position,
                flow_id: node.id,
                title: node.data.blueprint.title,
                dashboard_configuration: dashboard_configuration,
                ...node.data
            }
        }
        return updateUserPipe(pipe_id, params, username)
    }

    const updateNodeInPipe = (pipe_id, node_id, username, value) => {
        let params = {
            operation: "update_value",
            value: value
        }
        return updateNode(pipe_id, node_id, username, params)
    }

    const updateNodeFilterConfig = (pipe_id, node_id, username, value) => {
        let params = {
            operation: "update_filter_config",
            value: value,
        }
        return updateNode(pipe_id, node_id, username, params)
    }

    const updateNodeConfiguration = (pipe_id, node_id, username, configuration) => {
        let params = {
            operation: "update_configuration",
            value: configuration
        }
        return updateNode(pipe_id, node_id, username, params)
    }

    const updateNodeInDashboard = (pipe_id, node_id, username, view_in_dashboard) => {
        let params = {
            operation: "update_dashboard_flag",
            value: view_in_dashboard
        }
        return updateNode(pipe_id, node_id, username, params)
    }

    const updateNodeSkipFlag = (pipe_id, node_id, username, skip) => {
        let params = {
            operation: "update_skip_flag",
            value: skip
        }
        return updateNode(pipe_id, node_id, username, params)
    }

    const updateNodeDetails = (pipe_id, node_id, username, title, description) => {
        let params = {
            operation: "update_details",
            value: [title, description]
        }
        return updateNode(pipe_id, node_id, username, params)
    }

    const updateNode = (pipe_id, node_id, username, params) => {
        return state.current.client.post(`/user/${username}/pipe/${pipe_id}/block/${node_id}`, params, {
            needs_auth: true
        })
    }

    const moveNodeInPipe = (pipe_id, node_id, position, username) => {
        let params = {
            operation: "move_block",
            value: {
                block_id: node_id,
                position: position
            }
        }
        return updateUserPipe(pipe_id, params, username)
    }

    const deleteNodeFromPipe = (pipe_id, nodeId, username) => {
        let params = {
            operation: "delete_block",
            value: {
                block_id: nodeId
            }
        }
        return updateUserPipe(pipe_id, params, username)
    }

    const addEdgeToPipe = (pipe_id, edge, username) => {
        let params = {
            operation: "add_connection",
            value: edge
        }
        return updateUserPipe(pipe_id, params, username)
    }

    const deleteEdgeFromPipe = (pipe_id, edgeId, username) => {
        let params = {
            operation: "delete_connection",
            value: {
                connection_id: edgeId
            }
        }
        return updateUserPipe(pipe_id, params, username)
    }

    const renamePipe = (pipe_id, name, username) => {
        let params = {
            operation: "update_name",
            value: name
        }
        return updateUserPipe(pipe_id, params, username)
    }

    const savePipeSnapshot = (pipe_id, title = "", description = "", tags = []) => {
        let params = {
            title: title,
            description: description,
            tags: tags
        }
        return state.current.client.post(`/user/pipe/${pipe_id}/snapshot`, params, {
            needs_auth: true
        })
    }

    const uploadFiles = async (files, overwrite, onUploadProgress) => {
        let archive = await zipFiles(files)
        let formData = new FormData();
        formData.append("filename", archive);
        formData.append("metadata", JSON.stringify({
            overwrite: overwrite
        }));
        return state.current.client.post("/user/file/upload", formData, {
            needs_auth: true,
            timeout: 600000,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        })
    }

    const deleteFile = (filename) => {
        return state.current.client.delete(`/user/file/${filename}`, {
            needs_auth: true
        })
    }

    const runPipe = (id, until_node_id, row_limit, username, start, size, filters, globalFilter, sorting) => {
        let params = {
            until_node_id: until_node_id,
        }
        if (row_limit) {
            params.row_limit = row_limit
        }
        if (start) {
            params.start = start
        }
        if (size) {
            params.size = size
        }
        if (filters) {
            params.filters = filters
        }
        if (globalFilter) {
            params.global_filter = globalFilter
        }
        if (sorting) {
            params.sorting = sorting
        }
        return state.current.client.post(`/user/${username}/pipe/${id}/run`, params, {
            needs_auth: true
        })
    }

    const getSpec = ({ pipe_id, block_id, username, height, filter_list, signal }) => {
        let body = {
            height: height
        }
        if (filter_list) {
            body.filter = filter_list
        }
        return state.current.client.post(`/user/${username}/pipe/${pipe_id}/plot/${block_id}`, body, {
            needs_auth: true,
            signal: signal
        })
    }

    const getPlotPreview = ({ pipe_id, block_id, username, sparsify, height, signal }) => {
        return state.current.client.get(`/user/${username}/pipe/${pipe_id}/plot/${block_id}/preview`, {
            needs_auth: true,
            responseType: 'blob',
            signal: signal,
            params: {
                sparsify: sparsify,
                height: height
            }
        })
    }

    const uploadScreenshot = (pipe_id, screenshot_file, username) => {
        let params = {
            "operation": "update_thumbnail",
            "value": screenshot_file
        }
        return updateUserPipe(pipe_id, params, username)
    }

    const getUsers = () => {
        return state.current.client.get("/admin/users", {
            needs_auth: true
        })
    }

    const deleteUser = (user) => {
        return state.current.client.delete(`/admin/user/${user}`, {
            needs_auth: true
        })
    }

    const updateUserRole = (user, role) => {
        let params = {
            "operation": "change_role",
            "value": role
        }
        return state.current.client.post(`/admin/user/${user}`, params, {
            needs_auth: true
        })
    }

    const updateUserOrganization = (user, organization) => {
        let params = {
            "operation": "change_organization",
            "value": organization
        }
        return state.current.client.post(`/admin/user/${user}`, params, {
            needs_auth: true
        })
    }

    const updateUserCostCenters = (user, cost_centers) => {
        let params = {
            "operation": "change_cost_centers",
            "value": cost_centers
        }
        return state.current.client.post(`/admin/user/${user}`, params, {
            needs_auth: true
        })
    }

    const updateTemplateCostCenters = (template_id, cost_centers) => {
        let params = {
            "operation": "update_cost_centers",
            "value": cost_centers
        }
        return state.current.client.post(`/user/template/${template_id}`, params, {
            needs_auth: true
        })
    }

    const updateUserCrops = (user, crops) => {
        let params = {
            "operation": "change_crops",
            "value": crops
        }
        return state.current.client.post(`/admin/user/${user}`, params, {
            needs_auth: true
        })
    }

    setAxiosAuthInterceptor()
    setAxiosResponseInterceptor()

    return {
        currentError: currentError,
        currentUser: currentUser,
        currentUserUndefined: currentUserUndefined,
        setCurrentUserUndefined: setCurrentUserUndefined,

        ping: ping,
        signup: signup,
        login: login,
        resetPassword: resetPassword,
        getBlocks: getBlocks,
        getOrganizations: getOrganizations,
        getTemplates: getTemplates,
        getTemplate: getTemplate,
        createTemplate: createTemplate,
        updateTemplateTag: updateTemplateTag,
        deleteTemplate: deleteTemplate,
        getUserProfile: getUserProfile,
        getUserPipes: getUserPipes,
        getUserPipe: getUserPipe,
        getBlock: getBlock,
        createNewPipe: createNewPipe,
        clonePipe: clonePipe,
        deletePipe: deletePipe,
        getUserFiles: getUserFiles,
        getFile: getFile,
        updateFileDTypeMapping: updateFileDTypeMapping,
        updateFileCostCenters: updateFileCostCenters,
        updateFileCrop: updateFileCrop,
        getFilterConfig: getFilterConfig,
        updateUserPipe: updateUserPipe,
        addNodeToPipe: addNodeToPipe,
        updateNodeInPipe: updateNodeInPipe,
        updateNodeFilterConfig: updateNodeFilterConfig,
        updateNodeConfiguration: updateNodeConfiguration,
        updateNodeInDashboard: updateNodeInDashboard,
        updateNodeSkipFlag: updateNodeSkipFlag,
        updateNodeDetails: updateNodeDetails,
        moveNodeInPipe: moveNodeInPipe,
        deleteNodeFromPipe: deleteNodeFromPipe,
        addEdgeToPipe: addEdgeToPipe,
        deleteEdgeFromPipe: deleteEdgeFromPipe,
        renamePipe: renamePipe,
        savePipeSnapshot: savePipeSnapshot,
        uploadFiles: uploadFiles,
        deleteFile: deleteFile,
        runPipe: runPipe,
        getSpec: getSpec,
        getPlotPreview: getPlotPreview,
        uploadScreenshot: uploadScreenshot,
        getUsers: getUsers,
        deleteUser: deleteUser,
        updateUserRole: updateUserRole,
        updateUserOrganization: updateUserOrganization,
        updateUserCostCenters: updateUserCostCenters,
        updateTemplateCostCenters: updateTemplateCostCenters,
        updateUserCrops: updateUserCrops,
        updateBlockOrder: updateBlockOrder,
        getBranchMap: getBranchMap
    }
}

export default Api;
