import { useCallback, useRef, useState } from 'react';

import { Box, Stack } from "@mui/material";
import Logger from 'helper/Logger';
import { useURLParts } from 'helper/UrlUtils';
import useFeatureFlags from 'helper/useFeatureFlags';
import DataTransferKey from "../../helper/DataTransferKey";
import useWindowDimensions from "../../helper/WindowDimensions";
import PipelineGraphComponent from "./PipelineGraphComponent";
import BlockLibrarySidebarComponent from "./sidebar/BlockLibrarySidebarComponent";
import { ReactFlowProvider } from '@xyflow/react';

const FlowPageComponent = ({ }) => {
    const { height, width } = useWindowDimensions();
    const { pipeId, username, isTemplate } = useURLParts()
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const { currentUserIsAdmin } = useFeatureFlags()

    const isReadOnly = !currentUserIsAdmin() || isTemplate;

    const onDrop = (event) => {
        if (isReadOnly) return;
        event.preventDefault()

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
        const dataStr = event.dataTransfer.getData(DataTransferKey.NODE_DATA)
        const blueprint = JSON.parse(dataStr)

        // check if the dropped element is valid
        if (typeof blueprint === 'undefined' || !blueprint) {
            Logger.error("Invalid blueprint")
            return {}
        }

        const position = reactFlowInstance.screenToFlowPosition({
            x: event.clientX - reactFlowBounds.left + 150,
            y: event.clientY - reactFlowBounds.top,
        })

        let newNode = {
            type: "custom",
            position,
            width: 150,
            height: 80,
            data: {
                blueprint: blueprint,
                configuration: blueprint.default_configuration,
                data: {
                    value: {}
                },
            }
        }
        return newNode
    }

    return (
        <Stack direction="row" spacing={2} sx={{ mt: 0 }}>
            {!isReadOnly && (
                <BlockLibrarySidebarComponent height={height} />
            )}
            <ReactFlowProvider>
                <Box
                    ref={reactFlowWrapper}
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: isReadOnly ? 'center' : 'flex-start',
                        alignItems: isReadOnly ? 'center' : 'flex-start',
                        width: isReadOnly ? '100%' : 'auto',
                    }}
                >
                    <PipelineGraphComponent
                        height={height - 120}
                        width={width - (isReadOnly ? 0 : 300)}
                        onDrop={onDrop}
                        setReactFlowInstance={setReactFlowInstance}
                        isReadOnly={isReadOnly}
                    />
                </Box>
            </ReactFlowProvider>
        </Stack>
    );
};

export default FlowPageComponent;
