import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"

const ChangeBranchOrderDialog = ({ open, onClose, branchMap, branchOrder, selectedBranchNumber, handleBranchOrderChange, handleSubmitOrderChanges }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="change-order-dialog"
        >
            <DialogTitle>
                Change Branch Order <br/>
                <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>Current Branch: {selectedBranchNumber}</Typography>
            </DialogTitle>
            <DialogContent>
                {[...new Set(Object.values(branchMap))].filter(branchNumber => branchNumber !== 1 && branchNumber !== 0).map((branchNumber, index) => (
                    <Typography variant="body1" key={index}>
                        Branch {branchNumber} order:
                        <select
                            value={branchOrder[branchNumber] || branchNumber}
                            onChange={(e) => handleBranchOrderChange(branchNumber, e.target.value)}
                            disabled={branchNumber !== selectedBranchNumber}
                        >
                            {
                                [...new Set(Object.values(branchMap))].filter(availableBranchNumber => availableBranchNumber !== 1 && availableBranchNumber !== 0).map(availableBranchNumber => (
                                    <option key={availableBranchNumber} value={availableBranchNumber}>
                                        {availableBranchNumber}
                                    </option>
                                ))
                            }
                        </select>
                    </Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={handleSubmitOrderChanges} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeBranchOrderDialog